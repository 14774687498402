import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Plot from 'react-plotly.js'
import { request } from "graphql-request"
import { Page, PageHeading, SegmentedControl, ExternalLink, Searchbox, Button } from '@broad/ui'
import DocumentTitle from '../DocumentTitle'

import throttle from 'lodash.throttle'
import DETable from './DEList/DETable'
import sortGenes from './DEList/sortGenes'

import Userfront from '@userfront/react'
import { Redirect } from 'react-router-dom'
import fetch from 'graphql-fetch'


/* stylelint-disable block-no-empty */
const ControlWrapper = styled.span``
/* stylelint-enable block-no-empty */

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    ${ControlWrapper} {
      margin-bottom: 1em;
    }
  }
`

const PageContent = styled.div`
  width: 70%;
  text-align: justify;
`

const fetchSearchResults = query =>
  fetch(process.env.GNOMAD_API_URL)(
    `
  query Search($query: String!) {
    searchResults(query: $query) {
      label
      value: url
    }
  }
`,
    { query }
  ).then(response => response.data.searchResults)

const base_layout = {
  autosize: false,
  width: 650,
  height: 500,
  margin: {
    l: 60,
    r: 30,
    b: 60,
    t: 80
  },

  paper_bgcolor: 'rgb(243, 243, 243)',
  plot_bgcolor: 'rgb(243, 243, 243)',
  showlegend: false,

  xaxis: {
    title: 'log(Fold Change)'
  },

  yaxis: {
    title: '-log(p value)',
    rangemode: 'tozero'
  }
}

Userfront.init('8nw8qjpb')

class DiffExpressionPage extends Component {

  /*
	static propTypes = {
        gene_name: PropTypes.string,
	}
  */


  constructor(props) {
    super(props)

    const defaultSortKey = 'pvalue'
    const defaultSortOrder = 'ascending'

/*    const renderedVariants = sortVariants(
      mergeExomeAndGenomeData(filterVariants(props.variants, defaultFilter)),
      {
        sortKey: defaultSortKey,
        sortOrder: defaultSortOrder,
      }
    )
*/

    /*
    this.state = {
      filter: defaultFilter,
      hoveredVariant: null,
      rowIndexLastClickedInNavigator: 0,
      renderedVariants,
      visibleVariantWindow: [0, 19],
    }
    */

    this.state = {
      expression_data: null,
      group1: 'WT_Pkd1',
      group2: 'Pkd1_KO',
      timepoint: 'W7',
      sex: 'M',
      mouse_model: 'Pkd1',
      sortKey: defaultSortKey,
      sortOrder: defaultSortOrder,
      searchWord: '',
      searchData: [],
      visibleGeneWindow: [0, 14],
    }

  }
   onSort = newSortKey => {
      this.setState(state => {
        const { expression_data, sortKey } = this.state

        let newSortOrder = 'descending'
        if (newSortKey === sortKey) {
          newSortOrder = this.state.sortOrder === 'ascending' ? 'descending' : 'ascending'
        }

        // Since the filter hasn't changed, sort the currently rendered variants instead
        // of filtering the input variants.
        const sortedGenes = sortGenes(expression_data, {
          sortKey: newSortKey,
          sortOrder: newSortOrder,
        })

        return {
          expression_data: sortedGenes,
          sortKey: newSortKey,
          sortOrder: newSortOrder,
        }
      })
    }

    searchWord = (hit_record,hit) => {
      console.log("Word to search: " + hit.label)
      //console.log(hit)
      const tmp_search_data = this.state.expression_data.filter((word) => word.gene_name === hit.label)
      console.log(tmp_search_data)
      this.setState({searchWord: hit.label, visibleMetaboliteWindow: [0, 14], searchData: tmp_search_data})

    }

    clearSearchWord = () => {
      console.log("In clear search word")
      this.setState({searchWord: '', searchData: []})

    }

    onVisibleRowsChange = throttle(({ startIndex, stopIndex }) => {
      this.setState({ visibleGeneWindow: [startIndex, stopIndex] })
    }, 100)

    fetchExpression = async(time_point,genotype1,genotype2,sex) => {
        const query = `{
          diff_expression(time_point: "${time_point}", genotype1: "${genotype1}", genotype2: "${genotype2}", sex: "${sex}"){
            gene_name
            pvalue
            logfc
            avg_expression
          }
        }
        ` 
        try{
          console.log("Requesting data")
          console.log(query)
          const expression_data = await request("https://pkdgenesandmetabolism.org/api", query)    
          //console.log(expression_data)
          
          return expression_data.diff_expression
          //this.setState({data: expression_data})

        }catch(error){
          console.log(error)
          return undefined
          //console.log(error)
        }
    }

    async componentDidMount() {
        //componentDidMount() {

        const expression_data = await this.fetchExpression(this.state.timepoint,this.state.group1,this.state.group2, this.state.sex)

        this.mounted = true
        this.setState({expression_data: expression_data})

        console.log("In componentDidMount")

    }

    componentWillUnmount() {
        this.mounted = false
    }

    updateData = (expression_data) => {

        const defaultSortKey = 'pvalue'
        const defaultSortOrder = 'ascending'

        const sortedGenes = sortGenes(expression_data, {
          sortKey: defaultSortKey,
          sortOrder: defaultSortOrder,
        })
        
        this.setState({ sortKey: defaultSortKey,
                        sortOrder: defaultSortOrder,
                        visibleGeneWindow: [0, 14],
                        expression_data: sortedGenes})

    }

    filterData = (expression_data) => {
        let plot_data = []  
        let x_data = []
        let y_data = []
        
        for (var i = 0; i < expression_data.length; i++){
            x_data.push(expression_data[i].logfc)    
            y_data.push(-1*Math.log10(expression_data[i].pvalue))    
                    
        }

        plot_data.push({
            y: y_data,  
            x: x_data,
            type: 'scatter',
            mode: 'markers',
            name: 'Test1',
            hoverinfo: 'none',
            marker: { size: 4, color: 'rgb(174, 214, 241)'}
        }) 


        const window_start = this.state.visibleGeneWindow[0]
        const window_end = this.state.visibleGeneWindow[1]

        //console.log("start index: "+ window_start)
        //console.log("stop index: "+ window_end)

        /*
        for (var i= window_start; i <= window_end; i++){
          plot_data.push({
              y: [-1*Math.log10(expression_data[i].pvalue)],  
              x: [expression_data[i].logfc],
              type: 'scatter',
              mode: 'markers',
              hoverinfo: 'name',
              name: expression_data[i].gene_name,
              hoveron: 'points',
              marker: { size: 6, color: 'rgb(44, 160, 101)'}
          }) 

        }
        */

        // In case array is empty
        if(this.state.searchWord !== '' && this.state.searchData.length > 0){          
          
          plot_data.push({
              y: [-1*Math.log10(this.state.searchData[0].pvalue)],  
              x: [this.state.searchData[0].logfc],
              type: 'scatter',
              mode: 'markers',
              hoverinfo: 'name',
              name: this.state.searchData[0].gene_name,
              hoveron: 'points',
              marker: { size: 6, color: 'rgb(255, 185, 0)'}
          }) 

        }
        else{
          for (var i= window_start; i <= window_end; i++){
            plot_data.push({
                y: [-1*Math.log10(expression_data[i].pvalue)],  
                x: [expression_data[i].logfc],
                type: 'scatter',
                mode: 'markers',
                hoverinfo: 'name',
                name: expression_data[i].gene_name,
                hoveron: 'points',
                marker: { size: 6, color: 'rgb(44, 160, 101)'}
            }) 

          }

        }

        //console.log(plot_data)

        return plot_data

      }


	render() {

        /*
         if(!Userfront.accessToken()){
            return(
              <Redirect to={{pathname: '/login'}} />
            )
          }
        */
        if(!this.mounted){
          console.log("DiffExpressionTab not mounted")
          return(            
            <p>Loading</p>
          )
        }

        const config = {
            displayModeBar: false
        }

        //console.log(this.state.expression_data.diff_expression)        
        const plot_data = this.filterData(this.state.expression_data)
        //console.log("Visible Window Changed")
        //console.log(this.state.visibleGeneWindow)


    var timepoint_str = ""
    var sex_str = ""

    if(this.state.timepoint == 'W7'){
      timepoint_str = "Week 7"
    }
    else if(this.state.timepoint == 'W10'){
      timepoint_str = "Week 10"
    }

    if(this.state.sex == 'M'){
      sex_str = "Male"
    }
    else if(this.state.sex == 'F'){
      sex_str = "Female"
    }

    var group1_str = this.state.group1
    var group2_str = this.state.group2

    // temp fix
    if(group1_str === 'Pkd2_Kif3a_KO'){
      group1_str = 'Pkd2_Ift88_KO'
    }
    if(group2_str === 'Pkd2_Kif3a_KO'){
      group2_str = 'Pkd2_Ift88_KO'
    }

    const title_str = `${group1_str} vs ${group2_str} - ${sex_str}, ${timepoint_str}`

    var layout = {
      title: `${title_str}`,      
      ...base_layout
    }

		return (
        <Page>
        <DocumentTitle title="Differential Gene Expression" />
        <PageHeading>Differential Gene Expression</PageHeading>
            <Wrapper>
              Group 1: &nbsp; &nbsp; <SegmentedControl
                id="group1-selection"
                onChange={ g1 => {
                    this.setState({ group1: g1 })

                    console.log("Loading new data")
                    console.log("group1: "+this.state.group1)
                    console.log("g1 group1: "+g1)                    
                    console.log("group2: "+this.state.group2)

                    if(g1 === `${this.state.mouse_model}_KO`){
                      this.setState({ group2: `${this.state.mouse_model}_Kif3a_KO` })
                      this.fetchExpression(this.state.timepoint,g1,`${this.state.mouse_model}_Kif3a_KO`,this.state.sex).then( data =>{
                          //this.setState({expression_data: data})
                          this.updateData(data)
                        })
                    }
                    else{
                      this.fetchExpression(this.state.timepoint,g1,this.state.group2,this.state.sex).then( data =>{
                          //this.setState({expression_data: data})
                          this.updateData(data)
                        })
                    }
                    
                    //const expression_data = await this.fetchExpression("W7",this.state.group1,this.state.group2)
                    //this.setState({expression_data: expression_data})                    
                    
                }}
                options={[
                  { label: 'Normal', value: `WT_${this.state.mouse_model}`},
                  { label: `${this.state.mouse_model}_KO`, value: `${this.state.mouse_model}_KO` },
                  { label: this.state.mouse_model == 'Pkd1' ? 'Pkd1_Kif3a_KO': 'Pkd2_Ift88_KO', value: `${this.state.mouse_model}_Kif3a_KO`, disabled: true },                  
                ]}
                value={this.state.group1}
              />
              &nbsp; &nbsp; Timepoint: &nbsp; &nbsp;
              <SegmentedControl
                id="timepoint-selection"
                onChange={ t => {
                    this.setState({ timepoint: t })

                    this.fetchExpression(t,this.state.group1,this.state.group2,this.state.sex).then( data =>{
                        //this.setState({expression_data: data})
                        this.updateData(data)
                      })


                }}
                options={[
                  { label: 'Week 7', value: 'W7'},
                  { label: 'Week 10', value: 'W10'},
                ]}
                value={this.state.timepoint}
              />
              &nbsp; &nbsp; Sex: &nbsp; &nbsp;
              <SegmentedControl
                id="sex-selection"
                onChange={ s => {
                    this.setState({ sex: s })
                    this.fetchExpression(this.state.timepoint,this.state.group1,this.state.group2,s).then( data =>{
                        //this.setState({expression_data: data})
                        this.updateData(data)
                      })
                }}
                options={[
                  { label: 'Male', value: 'M'},
                  { label: 'Female', value: 'F'}
                ]}
                value={this.state.sex}
              />
              &nbsp; &nbsp; Mouse Model: &nbsp; &nbsp;
              <SegmentedControl
                id="mouse-model-selection"
                
                onChange={ m => {
                    this.setState({ mouse_model: m, group1: `WT_${m}`, group2: `${m}_KO`, timepoint: 'W10'})
                    
                    this.fetchExpression('W10',`WT_${m}`,`${m}_KO`,this.state.sex).then( data =>{
                        //this.setState({expression_data: data})
                        this.updateData(data)
                      })
                    
                }}
                

                options={[
                  { label: 'Pkd1', value: 'Pkd1'},
                  { label: 'Pkd2', value: 'Pkd2'}
                ]}
                value={this.state.mouse_model}
              />


            </Wrapper>
            <Wrapper>  
              Group 2: &nbsp; &nbsp; <SegmentedControl
                id="group2-selection"
                onChange={ g2 => {
                    this.setState({ group2: g2 })

                    console.log("Loading new data")
                    console.log("group1: "+this.state.group1)
                    console.log("group2: "+this.state.group2)
                    console.log("g2 group2: "+g2)

                    // const expression_data = await this.fetchExpression("W7",this.state.group1,this.state.group2)
                    //this.setState({expression_data: expression_data})

                    //this.fetchExpression("W7",this.state.group1,g2,this.state.sex).then( data =>{
                    this.fetchExpression(this.state.timepoint,this.state.group1,g2,this.state.sex).then( data =>{
                        //this.setState({expression_data: data})
                        this.updateData(data)
                      })

                }}
                options={[
                  { label: 'Normal', value: `WT_${this.state.mouse_model}`, disabled: true},
                  { label: `${this.state.mouse_model}_KO`, value: `${this.state.mouse_model}_KO`, disabled: !(this.state.group1 === `WT_${this.state.mouse_model}`) },
                  { label: this.state.mouse_model == 'Pkd1' ? 'Pkd1_Kif3a_KO': 'Pkd2_Ift88_KO', value: `${this.state.mouse_model}_Kif3a_KO` },                  
                ]}
                value={this.state.group2}
              />
            </Wrapper>
            <br />

            <Wrapper>
            <Plot
              data={plot_data}
              layout={layout}
              config={config}
            />
            <ControlWrapper> 
            <Searchbox
              onSelect={this.searchWord}
              fetchSearchResults={fetchSearchResults} 
              placeholder={'Search by gene'}
            />
            <Button onClick={this.clearSearchWord}>Clear</Button>

            <DETable
              sortKey={this.state.sortKey}
              sortOrder={this.state.sortOrder}
              de_genes={this.state.searchWord === '' ? this.state.expression_data : this.state.searchData}
              onRequestSort={this.onSort}
              onVisibleRowsChange = {this.onVisibleRowsChange}
            /> 
            </ControlWrapper>            
            </Wrapper>
            <br />
            Note: Positive Log(Fold Change) indicates up-regulation in Group1


            <PageContent>
            <h3>Overview of TRAP RNA-seq study design</h3>
            <img src="/TRAP_RNAseq.jpg" width="80%" height="80%"></img><br />
            For more details, please refer to the <ExternalLink href="/about">about page.</ExternalLink>
            <br /><br />
            <h3>Citation</h3>
            When using this data, please cite the following reference.<br />
            Zhang, C., Rehman, M., Tian, X., Pei, S.L.C., Gu, J., Bell, T.A., Dong, K., Tham, M.S., Cai, Y., Wei, Z., et al. (2024). Glis2 is an early effector 
            of polycystin signaling and a target for therapy in polycystic kidney disease. <ExternalLink href="https://www.nature.com/articles/s41467-024-48025-6">Nat. Commun. 15, 3698. 10.1038/s41467-024-48025-6.</ExternalLink>
            <br /><br />
            </PageContent>        
        </Page>
		)
	}
}

export default DiffExpressionPage
